<div class="component-container" style="height: calc(100vh - 67px)">
  <div
    class="component-heading-container d-flex align-items-center justify-content-between"
  >
    <div class="col-md-12" style="padding-right: 0px">
      <div class="row">
        <div class="col-md-2">
          <h3 class="component-heading-text">Generate Form Report</h3>
        </div>
        <div class="col-md-10 pr-0">
          <div class="row" style="margin-top: 10px">
            <div
              class="btn_wrapper"
              style="margin-left: auto; margin-right: 0px"
            >
              <button
                type="button"
                class="mat_btn_hov_act"
                mat-raised-button
                [matMenuTriggerFor]="menu"
                (click)="toggleDropdown()"
                style="
                  float: right;
                  margin-right: 5px;
                  font-size: 14px;
                  color: white;
                  background-color: var(--color-teal);
                "
              >
                Get Report
                <div style="margin-left: 13px">
                  <i
                    class="fas"
                    [ngClass]="isDropdownOpen ? 'fa-caret-up' : 'fa-caret-down'"
                    style="margin-right: px"
                  ></i>
                </div>
              </button>
              <mat-menu #menu="matMenu">
                <button
                  mat-menu-item
                  (click)="runQuery('Rules')"
                  *ngIf="selectedLOB != 'All'"
                >
                  Deployed Forms
                </button>
                <button mat-menu-item (click)="runQuery('Admin')">
                  Work In Progress Forms
                </button>
              </mat-menu>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="component-content-view">
    <div class="container-fluid">
      <div class="col-md-12 fast_admin">
        <label style="font-weight: bold" class="label_heading"
          >Select A Line Of Business To Begin:</label
        >
        <div class="selectdiv select_wrapinline" style="width: 100%">
          <label>
            <ng-select
              id="lineOfBusiness"
              class="custom-select"
              class="form-page-att-input"
              style="width: 100%"
              [items]="lineOfBusinessList"
              bindLabel="Description"
              bindValue="Name"
              [(ngModel)]="selectedLOB"
              [clearable]="false"
            >
              <ng-template ng-option-tmp let-item="item">
                <div title="{{ item.Description }}">{{ item.Description }}</div>
              </ng-template>
            </ng-select>
          </label>
        </div>

        <mat-slide-toggle style="float: right" (change)="toggle($event)">
          {{ this.toggleText }}
        </mat-slide-toggle>
        <mat-slide-toggle style="float: right" (change)="toggleExclude($event)">
          'ZZ' Conditions Only
        </mat-slide-toggle>
        <div class="shared-document-info" (click)="$event.stopPropagation()">
          <a
            (click)="toggleDocumentPanel($event)"
            matTooltip="Instructions"
            class="info-icon"
          >
            <i class="fas fa-info-circle" style="margin-top: 5px"></i>
          </a>

          <div
            class="document-panel"
            *ngIf="isDocumentPanelOpen"
            (click)="$event.stopPropagation()"
          >
            <div class="row" style="position: center">
              <b style="margin-bottom: 3px; margin-left: 174px">Instructions</b>
            </div>
            <p>
              -Select a Line of Business from the drop-down<br />
              -Select a CPM Element<br />
              -Select one or more element values to find matching forms and/or
              one or more mandatory element values*<br />
              -To add extra conditions select 'Add Condition'**<br />
              -Click 'Get Report for Deployed Forms' to find matching deployed
              forms, or 'Get Report for Work in Progress Forms' to find forms in
              the Document Attachment Workbench that match conditions<br />
              <br />
              *Matching forms will have rules that match any values inside one
              condition<br />
              **Matching forms must match on ALL conditions
            </p>
          </div>
        </div>
        <div class="row" style="display: inline-block; width: 100%">
          <div id="execute-rules-content-heading-container" class="col-12 p-0">
            <div
              class="heading-container fast_admin"
              style="
                display: flex;
                justify-content: space-between;
                align-items: center;
              "
            >
              <label class="label_heading ml-3">Report Query</label>
              <button
                (click)="addExpression()"
                class="mat_btn_hov_act"
                mat-raised-button
                style="
                  margin-top: 0;
                  font-size: 14px;
                  color: white;
                  margin-left: 0;
                  margin-bottom: 10px;
                  background-color: var(--color-teal);
                "
              >
                Add Condition
              </button>
            </div>
          </div>
          <div
            style="width: 100%; margin-left: 11px"
            *ngFor="let expression of expressionList"
          >
            <app-report-expression
              [currentExpression]="expression"
              (deleteExpression)="onDeleteExpression($event)"
            ></app-report-expression>
          </div>
        </div>
        <div>
          <b style="color: red" *ngIf="displayError"
            >No forms matched the query, no report was generated</b
          >
        </div>
      </div>
    </div>
  </div>
</div>
