//Angular Imports
import { Component, HostBinding } from "@angular/core";

//Third Party Imports
import { GridOptions } from "ag-grid-community";
import * as _ from "lodash";
import { ToastrService } from "ngx-toastr";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";

//Internal Imports

import "rxjs";
import { saveAs } from "file-saver";
import { forkJoin } from "rxjs";
import { MatButtonToggleModule } from "@angular/material/button-toggle";
import {
  AppInsightsService,
  ReportService,
  EnvironmentService,
  DateTimeUtcPipe,
  SharedService,
  ApplicationInsightsBaseComponent,
  CPMElement,
} from "../../framework";
import { EXECUTION_ENGINE_LIST } from "../configs";
import { ElementExpression, AdminForm, Rule } from "../../form-page";
import { ReportFormService } from "../services";
import { RuleEngineForm } from "../../form-page/models/ruleEngineForm.model";
import { ExportForm } from "../models/exportForm";
import { ReportExpression } from "../models/reportExpressionModel";
import { isNull, isNullOrUndefined } from "util";

/**
 * Forms Component
 */
@Component({
  selector: "app-report-query",
  templateUrl: "./report.component.html",
  styleUrls: ["./report.component.scss"],
  providers: [ReportFormService],
})
export class ReportComponent extends ApplicationInsightsBaseComponent {
  @HostBinding("style.width") width: string;

  initialRowDataLoad$;

  // Used to populate environment list on Unit Test and Run Test Groups tabs
  envList: any[];

  // Holds the environment name selected from the environment list on the Unit Test or Run Test Groups tab
  selectedEnv: string;

  // Populates the Report Query field on the Reporting tab
  // DB query string that will be executed to generate a report
  public reportQuery: string;

  //List of query expressions
  public expressionList: ReportExpression[] = [];

  //Master list for selected LOB
  public lineOfBusinessList: any[];

  public selectedLOB: any;

  public selectedAdminForms: AdminForm[];

  public selectedRuleEngineForms: RuleEngineForm[];

  public matchingAdminForms: AdminForm[];

  public matchingRuleEngineForms: RuleEngineForm[];

  public elementList: CPMElement[];

  public isFirst: boolean = true;

  public displayError: boolean = false;

  public toggleText = "Export without rules";
  public ZZOnly: boolean = false;
  isDropdownOpen = false; 

  isDocumentPanelOpen = false;
  /**
   * Constructor
   * @ignore
   */
  constructor(
    private _appInsightsService: AppInsightsService,
    private toastr: ToastrService,
    private _modal: NgbModal,
    private reportService: ReportFormService,
    private sharedService: SharedService,
    private dateTimePipe: DateTimeUtcPipe,
    private environmentService: EnvironmentService
  ) {
    super(_appInsightsService);
    this.width = "100%";
    this.envList = EXECUTION_ENGINE_LIST;
    this.selectedEnv = this.envList[0].Name;
  }

  //Angular Lifecycles
  /**
   * NgOnInit
   * @ignore
   */
  ngOnInit(): void {
    //Initiate environment and lob list, generate default expression with State as the default element as it is used the most
    this.selectedLOB = "All";
    this.selectedEnv = this.environmentService.environmentDetails.Environment;
    let expression: ReportExpression = new ReportExpression();
    expression.ElementName = "State";
    expression.Operator = "in";
    expression.IncludedList = [];
    expression.MandatoryList = [];
    this.expressionList.push(expression);
    this.sharedService.getMasterData().subscribe((el) => {
      this.lineOfBusinessList = _.clone(el.LineOfBusinessList);
      this.lineOfBusinessList.push({ Name: "All", Description: "All" });
      console.log(this.lineOfBusinessList);

      if (this.lineOfBusinessList && this.lineOfBusinessList.length > 0) {
        // PB - MATS-10160 - modified to sort on Description rather than Name
        this.lineOfBusinessList.sort((a: any, b: any) => {
          var nameA = a.Description.toLowerCase();
          var nameB = b.Description.toLowerCase();
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }

          // names must be equal
          return 0;
        });
      }
    });
    this.sharedService.getAllCpmElements().subscribe((el) => {
      this.elementList = el;
    });
  }

  /**
   * NgOnDestroy
   * @ignore
   */
  ngOnDestroy(): void { }

  toggle(event) {
    console.log(event);
    if (event.checked) {
      this.toggleText = "Export with rules";
    } else {
      this.toggleText = "Export without rules";
    }
  }
  toggleExclude(event) {
    if (event.checked) {

      this.ZZOnly = true;
    }
    else {
      this.ZZOnly = false;
    }
  }
 
  toggleDocumentPanel(event: Event): void {
    this.isDocumentPanelOpen = !this.isDocumentPanelOpen;
  }

  closeDocumentPanel(event: Event): void {
    this.isDocumentPanelOpen = false;
  }
  
  onDeleteExpression(event: ReportExpression) {
    let index = this.expressionList.indexOf(event);
    if (index >= 0) {
      this.expressionList.splice(index, 1);
    }
    if (this.expressionList.length == 1) {
      this.isFirst = true;
    }
  }

  addExpression() {
    let expression: ReportExpression = new ReportExpression();
    expression.ElementName = "State";
    expression.Operator = "in";
    expression.IncludedList = [];
    expression.MandatoryList = [];
    this.expressionList.push(expression);
    this.isFirst = false;
  }

  // Runs a query for reporting, choose function based on db selected, if no forms return display error and do not return spreadsheet
  runQuery(db: string): void {
    this.displayError = false;
    if (db == "Admin") {
      if (this.selectedLOB == "All") {
        this.reportService.getAllAdminForms().subscribe((res) => {
          this.selectedAdminForms = res;
          this.processAdminQueryAll();
          if (this.matchingAdminForms.length > 0) {
            this.exportAdminQueryAll();
          } else {
            this.displayError = true;
          }
        });
      } else {
        this.reportService
          .getAdminFormsByLOB(this.selectedLOB)
          .subscribe((res) => {
            this.selectedAdminForms = res;
            this.processAdminQuery();
            if (this.matchingAdminForms.length > 0) {
              this.exportAdminQuery();
            } else {
              this.displayError = true;
            }
          });
      }
    } else if (db == "Rules") {
      this.reportService
        .getRuleEngineFormsByLOB(this.selectedLOB)
        .subscribe((res) => {
          this.selectedRuleEngineForms = res;
          this.processRuleEngineQuery();
          if (this.matchingRuleEngineForms.length > 0) {
            this.exportRulesEngineQuery();
          } else {
            this.displayError = true;
          }
        });
    }
  }
  //Populate all LOB query, use the master attribute set (always first) to set dates
  exportAdminQueryAll() {
    var exportingForms: ExportForm[] = [];
    for (var form of this.matchingAdminForms) {
      let exForm = new ExportForm();
      exForm.InternalFormNumber = form.InternalFormNumber;
      exForm.ExternalFormNumber = form.ExternalFormNumber;
      exForm.FormName = form.AttributeSets[0].FormName;
      exForm.EffectiveDate = new Date(
        form.AttributeSets[0].EffectiveDate * 1000
      )
        .toISOString()
        .slice(0, 10);
      if (form.AttributeSets[0].ExpirationDate !== null) {
        exForm.ExpirationDate = new Date(
          form.AttributeSets[0].ExpirationDate * 1000
        )
          .toISOString()
          .slice(0, 10);
      }
      if (form.AttributeSets[0].SubmissionExpirationDate !== null) {
        exForm.SubmissionExpirationDate = new Date(
          form.AttributeSets[0].SubmissionExpirationDate * 1000
        )
          .toISOString()
          .slice(0, 10);
      }
      exForm.LOB = form.LOB.map((x) => x).join(",");
      exForm.FormType = form.AttributeSets[0].FormType;
      exportingForms.push(exForm);
    }
    const replacer = (key, value) => (value === null ? "" : value);
    const header = [
      "InternalFormNumber",
      "ExternalFormNumber",
      "FormName",
      "LOB",
      "FormType",
      "EffectiveDate",
      "ExpirationDate",
      "SubmissionExpirationDate",
    ];
    let csv = exportingForms.map((row) =>
      header
        .map((fieldName) => JSON.stringify(row[fieldName], replacer))
        .join(",")
    );
    csv.unshift(header.join(","));
    for (var i = 0; i < this.expressionList.length; i++) {
      var conditionString =
        this.expressionList[i].ElementName +
        " " +
        this.expressionList[i].Operator;
      var includedStrings: string[] = [];
      var mandatoryStrings: string[] = [];
      for (var j = 0; j < this.expressionList[i].IncludedList.length; j++) {
        includedStrings.push(this.expressionList[i].IncludedDescriptions[j]);
      }
      if (includedStrings.length > 0) {
        conditionString =
          conditionString + " OPTIONAL VALUES: " + includedStrings;
      }
      for (var j = 0; j < this.expressionList[i].MandatoryList.length; j++) {
        mandatoryStrings.push(this.expressionList[i].MandatoryDescriptions[j]);
      }
      if (mandatoryStrings.length > 0) {
        conditionString =
          conditionString + "| MANDATORY VALUES: " + mandatoryStrings;
      }
      +this.expressionList;
      csv.unshift(conditionString);
    }
    let csvArray = csv.join("\r\n");
    var blob = new Blob([csvArray], { type: "text/csv" });
    saveAs(blob, "report.csv");
  }

  //Export info from matching admin forms, find LOB attribute set for dates
  exportAdminQuery() {
    var exportingForms: ExportForm[] = [];
    const replacer = (key, value) => (value === null ? "" : value);
    const header = [
      "InternalFormNumber",
      "ExternalFormNumber",
      "FormName",
      "LOB",
      "EffectiveDate",
      "ExpirationDate",
      "SubmissionExpirationDate",
    ];
    let csv: string[] = [];
    csv.push(header.join(","));
    for (var form of this.matchingAdminForms) {
      let exForm = new ExportForm();
      exForm.InternalFormNumber = form.InternalFormNumber;
      exForm.ExternalFormNumber = form.ExternalFormNumber;
      exForm.LOB = this.selectedLOB;
      var attributeSet = form.AttributeSets.find(
        (i) => i.Scope == this.selectedLOB
      );
      if (attributeSet !== undefined) {
        exForm.FormName = attributeSet.FormName;

        exForm.EffectiveDate = new Date(attributeSet.EffectiveDate * 1000)
          .toISOString()
          .slice(0, 10);

        if (attributeSet.ExpirationDate !== null) {
          exForm.ExpirationDate = new Date(attributeSet.ExpirationDate * 1000)
            .toISOString()
            .slice(0, 10);
        }
        if (attributeSet.SubmissionExpirationDate !== null) {
          exForm.SubmissionExpirationDate = new Date(
            attributeSet.SubmissionExpirationDate * 1000
          )
            .toISOString()
            .slice(0, 10);
        }
      }
      csv.push(
        exForm.InternalFormNumber +
        "," +
        exForm.ExternalFormNumber +
        "," +
        exForm.LOB +
        "," +
        exForm.FormName +
        "," +
        exForm.EffectiveDate +
        "," +
        exForm.ExpirationDate +
        "," +
        exForm.SubmissionExpirationDate
      );
      if (this.toggleText == "Export with rules") {
        let ruleset = form.RuleSetups.find(
          (r) => r.LOB.indexOf(this.selectedLOB) != -1
        );
        let rules = ruleset.RuleSet.Rules;
        for (var rule of rules) {
          var csvString = rule.Action + " Rule: , ";
          for (var expression of rule.Expressions) {
            expression = this.getLabels(expression);
            csvString +=
              expression.ElementName +
              " " +
              expression.Operator +
              " " +
              " OPTIONAL VALUES: " +
              expression.IncludedList.join("/") +
              " | MANDATORY VALUES: " +
              expression.MandatoryList.join("/") +
              " ,";
          }
          csv.push(csvString);
        }
      }
      //exportingForms.push(exForm);
    }

    /*let csv = exportingForms.map(row => header.map(fieldName => JSON.stringify(row[fieldName], replacer)).join(','));
    csv.unshift(header.join(','));
     for (var i = 0; i < this.expressionList.length; i++) {
      var conditionString = this.expressionList[i].ElementName + " " + this.expressionList[i].Operator;
      var includedStrings: string[] = [];
      var mandatoryStrings: string[] = [];
      for (var j = 0; j < this.expressionList[i].IncludedList.length; j++) {
        includedStrings.push(this.expressionList[i].IncludedDescriptions[j]);
      }
      if (includedStrings.length > 0) {
        conditionString = conditionString + " OPTIONAL VALUES: " + includedStrings;
      }
      for (var j = 0; j < this.expressionList[i].MandatoryList.length; j++) {
        mandatoryStrings.push(this.expressionList[i].MandatoryDescriptions[j]);
      }
      if (mandatoryStrings.length > 0) {
        conditionString = conditionString + "| MANDATORY VALUES: " + mandatoryStrings;
      }+ this.expressionList
      csv.unshift(conditionString);
    }*/
    let csvArray = csv.join("\r\n");
    var blob = new Blob([csvArray], { type: "text/csv" });
    saveAs(blob, "report.csv");
  }

  //Export matching rule engine forms info
  exportRulesEngineQuery() {
    var exportingForms: ExportForm[] = [];
    const replacer = (key, value) => (value === null ? "" : value);
    const header = [
      "InternalFormNumber",
      "ExternalFormNumber",
      "FormName",
      "LOB",
      "EffectiveDate",
      "ExpirationDate",
      "SubmissionExpirationDate",
      "WritingCompanies",
      "States",
      "IndustryTemplates"
    ];
    let csv: string[] = [];
    csv.push(header.join(","));
    for (var form of this.matchingRuleEngineForms) {
      let exForm = new ExportForm();
      exForm.InternalFormNumber = form.InternalFormNumber;
      exForm.ExternalFormNumber = form.ExternalFormNumber;
      exForm.LOB = this.selectedLOB;
      exForm.FormName = form.FormName;
      exForm.WritingCompanies = form.WritingCompanies;
      exForm.States = form.States;
      exForm.IndustryTemplates = form.IndustryTemplates;
      exForm.EffectiveDate = new Date(form.EffectiveDate * 1000)
        .toISOString()
        .slice(0, 10);

      if (form.ExpirationDate !== null) {
        exForm.ExpirationDate = new Date(form.ExpirationDate * 1000)
          .toISOString()
          .slice(0, 10);
      }
      if (form.SubmissionExpirationDate !== null) {
        exForm.SubmissionExpirationDate = new Date(
          form.SubmissionExpirationDate * 1000
        )
          .toISOString()
          .slice(0, 10);
      }
      csv.push(
        exForm.InternalFormNumber +
        "," +
        exForm.ExternalFormNumber +
        "," +
        exForm.LOB +
        "," +
        exForm.FormName +
        "," +
        exForm.EffectiveDate +
        "," +
        exForm.ExpirationDate +
        "," +
        exForm.SubmissionExpirationDate +
        "," +
        exForm.WritingCompanies +
        "," +
        exForm.States +
        "," +
        exForm.IndustryTemplates

      );
      if (this.toggleText == "Export with rules") {
        let rules = form.RuleSet.Rules;
        for (var rule of rules) {
          var csvString = rule.Action + " Rule: , ";
          for (var expression of rule.Expressions) {
            expression = this.getLabels(expression);
            csvString +=
              expression.ElementName +
              " " +
              expression.Operator +
              " " +
              " OPTIONAL VALUES: " +
              expression.IncludedList.join("/") +
              " | MANDATORY VALUES: " +
              expression.MandatoryList.join("/") +
              " ,";
          }
          csv.push(csvString);
        }
      }

      //exportingForms.push(exForm);
    }

    /*let csv = exportingForms.map(row => header.map(fieldName => JSON.stringify(row[fieldName], replacer)).join(','));
    csv.unshift(header.join(','));
     for (var i = 0; i < this.expressionList.length; i++) {
      var conditionString = this.expressionList[i].ElementName + " " + this.expressionList[i].Operator;
      var includedStrings: string[] = [];
      var mandatoryStrings: string[] = [];
      for (var j = 0; j < this.expressionList[i].IncludedList.length; j++) {
        includedStrings.push(this.expressionList[i].IncludedDescriptions[j]);
      }
      if (includedStrings.length > 0) {
        conditionString = conditionString + " OPTIONAL VALUES: " + includedStrings;
      }
      for (var j = 0; j < this.expressionList[i].MandatoryList.length; j++) {
        mandatoryStrings.push(this.expressionList[i].MandatoryDescriptions[j]);
      }
      if (mandatoryStrings.length > 0) {
        conditionString = conditionString + "| MANDATORY VALUES: " + mandatoryStrings;
      }+ this.expressionList
      csv.unshift(conditionString);
    }*/
    let csvArray = csv.join("\r\n");
    var blob = new Blob([csvArray], { type: "text/csv" });
    saveAs(blob, "report.csv");
  }

  getLabels(expression: ElementExpression) {
    let selectedElement = this.elementList.find(
      (x) => x.CPMDataElementName === expression.ElementName
    );
    //let selectedElementName = selectedElement.CPMDataElementName;
    for (var i = 0; i < expression.IncludedList.length; i++) {
      var description = "";
      try {
        description = selectedElement.CPMFieldValues.find(
          (x) => x.Code == expression.IncludedList[i]
        ).Description;
      } catch (e) {
        description = "DESCRIPTION NOT FOUND";
      }

      //console.log(description);
      let code = expression.IncludedList[i];
      expression.IncludedList[i] = code + " = " + description;
    }
    for (var i = 0; i < expression.MandatoryList.length; i++) {
      var description = "";
      try {
        description = selectedElement.CPMFieldValues.find(
          (x) => x.Code == expression.MandatoryList[i]
        ).Description;
      } catch (e) {
        description = "DESCRIPTION NOT FOUND";
      }
      //console.log(description);
      let code = expression.MandatoryList[i];
      expression.MandatoryList[i] = code + " = " + description;
    }

    return expression;
  }

  //For each form for the LOB, evaluate all rules and add to matching forms if at least one rule includes the condition(s) specified
  processAdminQuery() {
    this.matchingAdminForms = [];
    for (var form of this.selectedAdminForms) {
      //Null checking for rule setup as some forms may have incomplete data
      var ruleSetup = form.RuleSetups.find(
        (i) => i.LOB.indexOf(this.selectedLOB) != -1
      );
      if (ruleSetup !== undefined && ruleSetup.hasOwnProperty("RuleSet")) {
        var ruleSet = form.RuleSetups.find(
          (i) => i.LOB.indexOf(this.selectedLOB) != -1
        ).RuleSet;
        for (var rule of ruleSet.Rules) {
          if (rule.Action != "Remove") {
            var match = this.processRule(rule);
            if (match) {
              this.matchingAdminForms.push(form);
              break;
            }
          }
        }
      }
    }
    console.log(this.matchingRuleEngineForms);
  }
  //For each form, evaluate all rules and add to matching forms if at least one rule includes the condition(s) specified
  processAdminQueryAll() {
    this.matchingAdminForms = [];
    for (var form of this.selectedAdminForms) {
      var match = false;
      //Since we have to iterate through all rule setups for the All query, break when a match is already made so we don't introduce duplicate records to matching forms
      for (var ruleSetup of form.RuleSetups) {
        if (match) {
          break;
        }
        //Null checking for rule setup as some forms may have incomplete data
        if (ruleSetup !== undefined && ruleSetup.hasOwnProperty("RuleSet")) {
          var ruleSet = ruleSetup.RuleSet;

          for (var rule of ruleSet.Rules) {
            if (rule.Action != "Remove") {
              match = this.processRule(rule);
              if (match) {
                this.matchingAdminForms.push(form);
                break;
              }
            }
          }
        }
      }
    }
  }

  //For each form for the LOB, evaluate all rules and add to matching forms if at least one rule includes the condition(s) specified
  processRuleEngineQuery() {
    this.matchingRuleEngineForms = [];
    for (var form of this.selectedRuleEngineForms) {
      if (!isNullOrUndefined(form.RuleSet?.Rules)) {
        for (var rule of form.RuleSet.Rules) {
          if (rule.Action != "Remove") {
            var match = this.processRule(rule);
            if (match) {
              for (var rexp of rule.Expressions) {
                if (rexp.ElementName == "WritingCompany" || rexp.ElementName == "Writing Company") {
                  var tempCompanies: string[] = [];
                  if (rexp.IncludedList.length > 0) {
                    tempCompanies = tempCompanies.concat(rexp.IncludedList);
                  }
                  if (rexp.MandatoryList.length > 0) {
                    tempCompanies = tempCompanies.concat(rexp.MandatoryList);
                  }
                  form.WritingCompanies = tempCompanies.map(x => x).join("_");

                }
                else if (rexp.ElementName == "State") {
                  var tempCompanies: string[] = [];
                  if (rexp.IncludedList.length > 0) {
                    tempCompanies = tempCompanies.concat(rexp.IncludedList);
                  }
                  if (rexp.MandatoryList.length > 0) {
                    tempCompanies = tempCompanies.concat(rexp.MandatoryList);
                  }
                  form.States = tempCompanies.map(x => x).join("_");

                }
                else if (rexp.ElementName == "BusinessSegment") {
                  var tempCompanies: string[] = [];
                  if (rexp.IncludedList.length > 0) {
                    tempCompanies = tempCompanies.concat(rexp.IncludedList);
                  }
                  if (rexp.MandatoryList.length > 0) {
                    tempCompanies = tempCompanies.concat(rexp.MandatoryList);
                  }
                  form.IndustryTemplates = tempCompanies.map(x => x).join("_");

                }
              }
              this.matchingRuleEngineForms.push(form);
              break;
            }
          }
        }
      }

    }
    console.log(this.matchingRuleEngineForms);
  }
  //Iterate through each expression in the query, if the rule has any expression which matches the current query expression, set match to true, if a query expression does not match any rule expressions, set match to false and immediately return
  processRule(rule: Rule) {
    var match: boolean = false;

    for (var expression of this.expressionList) {
      var ruleExpressions = rule.Expressions.filter(
        (e) =>
          e.ElementName.replace(/\s/g, "") ==
          expression.ElementName.replace(/\s/g, "") &&
          (e.Operator == "in" || e.Operator == "=")
      );
      var antiExpressions = rule.Expressions.filter(
        (e) =>
          e.ElementName.replace(/\s/g, "") ==
          expression.ElementName.replace(/\s/g, "") &&
          (e.Operator == "<>" || e.Operator == "all except")
      );
      if (ruleExpressions.length > 0) {
        for (var ruleExpression of ruleExpressions) {
          if (this.ZZOnly) {
            if (ruleExpression.IncludedList.indexOf("ZZ") >= 0 || ruleExpression.MandatoryList.indexOf("ZZ") >= 0) {
              match = true;
            }
          }
          else if (
            (expression.IncludedList.indexOf("ZZ") >= 0 &&
              ruleExpression.IncludedList.length > 0) ||
            (expression.MandatoryList.indexOf("ZZ") >= 0 &&
              ruleExpression.MandatoryList.length > 0)
          ) {
            match = true;
          } else if (
            (ruleExpression.IncludedList.indexOf("ZZ") >= 0 &&
              expression.IncludedList.length > 0) ||
            (ruleExpression.MandatoryList.indexOf("ZZ") >= 0 &&
              expression.MandatoryList.length > 0)
          ) {
            match = true;
          } else if (
            ruleExpression.IncludedList.some(
              (r) => expression.IncludedList.indexOf(r) >= 0
            ) ||
            ruleExpression.MandatoryList.some(
              (r) => expression.MandatoryList.indexOf(r) >= 0
            )
          ) {
            match = true;
          } else {
            match = false;
            return match;
          }
        }
      }
      if (
        antiExpressions.length > 0 &&
        expression.IncludedList.indexOf("ZZ") < 0 &&
        expression.MandatoryList.indexOf("ZZ") < 0
      ) {
        for (var ruleExpression of antiExpressions) {
          if (
            !ruleExpression.IncludedList.some(
              (r) => expression.IncludedList.indexOf(r) >= 0
            ) ||
            !ruleExpression.MandatoryList.some(
              (r) => expression.MandatoryList.indexOf(r) >= 0
            )
          ) {
            match = true;
          } else {
            match = false;
            return match;
          }
        }
      }
    }
    return match;

    /*for (var expression of this.expressionList) {
      if (rule.Expressions.some(ruleExp => (ruleExp.ElementName.replace(/\s/g, "") == expression.ElementName.replace(/\s/g, "")) &&  ((ruleExp.Operator == "in" || ruleExp.Operator == "=")
        && (ruleExp.IncludedList.some(r => expression.IncludedList.indexOf(r) >= 0 ) || (expression.IncludedList.length > 0 && ruleExp.IncludedList.some(r=> r == 'ZZ') )
        || ruleExp.MandatoryList.some(r => expression.MandatoryList.indexOf(r) >= 0 ) || (expression.MandatoryList.length > 0 && ruleExp.MandatoryList.some(r=> r == 'ZZ') ))) ||
         ((ruleExp.Operator == "all except" || ruleExp.Operator == "<>") && !(ruleExp.IncludedList.some(r => expression.IncludedList.indexOf(r) >= 0) || ruleExp.MandatoryList.some(r => expression.MandatoryList.indexOf(r) >= 0) ))) )
      {
        match = true;
      }
      else {
        match = false;
        return match;
      }

    }*/
    return match;
  }

  toggleDropdown() {
    this.isDropdownOpen = !this.isDropdownOpen;
  }
  
}
