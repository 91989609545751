import { Component, OnInit, Input,  Renderer2, HostBinding, HostListener } from '@angular/core';
import { GridOptions } from 'ag-grid-community';
import {
  AppInsightsService,
  DateFilterComponent,
  BooleanFilterComponent,
  DateTimePipe,
  EnvironmentService,
  DISPLAY_ENVIRONMENTS,
  AuthorizationService,
} from "../../../framework";

import { DeployPackage } from '../..';
import _ from 'lodash';
import { isNullOrUndefined } from 'util';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-deploy-detailview',
  templateUrl: './deploy-detailview.component.html',
  styleUrls: ['./deploy-detailview.component.scss']
})
export class DeployDetailviewComponent implements OnInit {
  @HostBinding("style.width") width: string;
  @Input() historyGridOptions: GridOptions;
  @Input() formsGridOptions: GridOptions;
  @Input() historyData: any[];
  @Input() formsData: any[];
  isOverlayVisible = true;
 // @Output() closeOverlay = new EventEmitter<void>();

  initialRowDataLoad$;

  /**
   * Environment List
   */
  environmentList: any[];
 

  constructor(private renderer: Renderer2, public activeModal: NgbActiveModal,private dateTimePipe: DateTimePipe, private _appInsightsService: AppInsightsService,private authService: AuthorizationService, private envService: EnvironmentService,) {
    (_appInsightsService);
    this.width = "100%";

    let environment = this.envService.environmentDetails.Environment;
    this.environmentList = DISPLAY_ENVIRONMENTS[environment];
    
  }
  

  ngOnInit(): void {
    

    this.configureHistoryGrid();
    this.configureFormsGrid();
  }
 
  
  private isToBeDeployedChecked(
    packageData: DeployPackage,
    environment: string
  ): boolean {
    let isSelected: boolean = false;
    if (
      packageData.EnvDeploymentStates[environment] &&
      packageData.EnvDeploymentStates[environment].ToBeDeployed
    ) {
      isSelected = true;
    }
    return isSelected;
  }
  private deployedDate(
    packageData: DeployPackage,
    environment: string
  ): string {
    let deployedBy: string;
    _.forEach(packageData.DeploymentHistories, (env) => {
      if (env.Target == environment) {
        deployedBy = "<br/>" + this.dateTimePipe.transform(env.DeployedDate);
      }
    });

    return deployedBy;
  }

  private addEnvironmentColumns(columns: any[]): any[] {
    for (let env of this.environmentList) {
      let colDef = {
        headerName: env["Name"],
        field: env["Value"],
        suppressSorting: true,
        filter: "booleanFilterComponent",
        volatile: true,
        valueGetter: (params) => {
          let isDeployed = this.isDeployedChecked(params.data, env["Value"]);
          let isChecked = this.isToBeDeployedChecked(params.data, env["Value"]);
          return isDeployed || isChecked ? "true" : "false";
        },
        cellStyle: (params) => {
          if (this.isDeployedChecked(params.data, env["Value"])) {
            return { backgroundColor: "lightgreen" };
          }
        },
        cellRenderer: (params) => {
          let isChecked = this.isDeployedChecked(params.data, env["Value"]);
          let isSelected = this.isToBeDeployedChecked(
            params.data,
            env["Value"]
          );
          let deployDate = this.deployedDate(params.data, env["Value"]);
          if (isNullOrUndefined(deployDate)) {
            deployDate = "";
          }

          if (env["IsDev"] === "true") {
            // is this a dev environment
            if (this.authService.PackageDeployAny()) {
              return (
                `<input type='checkbox' ${isSelected ? "checked" : ""} name="${
                  env["Value"]
                }" data-action-type="Selection"/>` + deployDate
              );
            } else {
              return (
                `<input type='checkbox' ${isSelected ? "checked" : ""} name="${
                  env["Value"]
                }" data-action-type="Selection" disabled/>` + deployDate
              );
            }
          } else if (env["IsTest"] === "true") {
            // is this a test environment
            if (this.authService.PackageDeployAny()) {
              return (
                `<input type='checkbox' ${isSelected ? "checked" : ""} name="${
                  env["Value"]
                }" data-action-type="Selection"/>` + deployDate
              );
            } else {
              return (
                `<input type='checkbox' ${isSelected ? "checked" : ""} name="${
                  env["Value"]
                }" data-action-type="Selection" disabled/>` + deployDate
              );
            }
          } else if (env["IsProd"] === "true") {
            // is this the prod environment
            if (this.authService.PackageDeployAny()) {
              return (
                `<input type='checkbox' ${isSelected ? "checked" : ""} name="${
                  env["Value"]
                }" data-action-type="Selection"/>` + deployDate
              );
            } else {
              return (
                `<input type='checkbox' ${isSelected ? "checked" : ""} name="${
                  env["Value"]
                }" data-action-type="Selection" disabled/>` + deployDate
              );
            }
          } else {
            // bad configuration
            throw "Configuration of deploy environments is bad.";
          }
        },
      };

      columns.push(colDef);
    }

    return columns;
  }

  private isDeployedChecked(
    packageData: DeployPackage,
    environment: string
  ): boolean {
    let isSelected: boolean = false;
    if (
      packageData.EnvDeploymentStates[environment] &&
      packageData.EnvDeploymentStates[environment].IsDeployed
    ) {
      isSelected = true;
    }
    return isSelected;
  }
  private deployedBy(packageData: DeployPackage, environment: string): string {
    let deployedBy: string;
    _.forEach(packageData.DeploymentHistories, (env) => {
      if (env.Target == environment) {
        deployedBy =
          env.DeployedBy +
          "<br/>" +
          this.dateTimePipe.transform(env.DeployedDate);
      }
    });
    return deployedBy;
  }
  configureHistoryGrid(): void {
    this.initialRowDataLoad$ = [];
    this.historyGridOptions = <GridOptions>{
      rowSelection: "single",
      domLayout: 'normal',
      columnDefs: this.createHistoryColumnDef(),
      enableFilter: true,
      enableSorting: true,
      pagination: true,
      paginationPageSize: 1,
      rowHeight: 50,
      enableColResize: true,
      context: { componentParent: this },
      frameworkComponents: {
        dateFilterComponent: DateFilterComponent,
        booleanFilterComponent: BooleanFilterComponent,
      },
      onGridReady: () => {
        this.historyGridOptions.api.setRowData(this.historyData);
        this.historyGridOptions.api.sizeColumnsToFit();
      },
      onRowClicked: (event) => {},
      onFilterChanged: (event) => {},
      onSortChanged: (event) => {},
      onFilterModified: function (event) {
        //console.log(event);
      },
    };
  }

  private createHistoryColumnDef(): any[] {
    let columns = [
      {
        headerName: "Package Name",
        field: "PackageName",
        tooltipValueGetter: (params) => params.value,
        headerTooltip: "Package Name",
        resizable: true,
        filter: "agTextColumnFilter",
        minWidth:150,
        cellRenderer: (params) => {
          return `<span> ${params.value} </span>`;
        },
      },
      {
        headerName: "LOB",
        field: "LOB",
        headerTooltip: "LOB",
        minWidth:100,
        tooltipValueGetter: (params) => params.value,
        resizable: true,
        filter: "agTextColumnFilter",
      },
      {
        headerName: "Release Tag",
        field: "Release",
        headerTooltip: "Release Tag",
        minWidth:150,
        tooltipValueGetter: (params) => params.value,
        resizable: true,
        filter: "agTextColumnFilter",
      },
      {
        headerName: "Created Date",
        field: "CreatedDate",
        headerTooltip: "Created Date",
        minWidth:150,
        tooltipValueGetter: (params) => params.value,
        resizable: true,
        filter: "dateFilterComponent",
        sortable: true,
        sort: "desc",
        cellRenderer: (params) => {
          return this.dateTimePipe.transform(
            params.value,
            "string",
            "MM/DD/YYYY h:mm A"
          );
        },
      },
      {
        headerName: "Published By",
        field: "CreatedBy",
        headerTooltip: "Published By",
        minWidth:150,
        tooltipValueGetter: (params) => params.value,
        resizable: true,
        filter: "agTextColumnFilter",
      },
    ];

    columns = this.addEnvironmentHistoryColumns(columns);
    return columns;
  }
  
  private addEnvironmentHistoryColumns(columns: any[]): any[] {
    for (let env of this.environmentList) {
      let colDef = {
        headerName: env["Name"],
        field: env["Value"],
        tooltipValueGetter: (params) => params.value,
        suppressSorting: true,
        filter: "booleanFilterComponent",
        minWidth:140,
        headerTooltip: env["Name"],
      
        valueGetter: (params) => {
          let isDeployed = this.isDeployedChecked(params.data, env["Value"]);
          let isChecked = this.isToBeDeployedChecked(params.data, env["Value"]);
          return isDeployed || isChecked ? "true" : "false";
        },
        cellRenderer: (params) => {
          let isDeployed = this.isDeployedChecked(params.data, env["Value"]);
          if (!isDeployed) {
            return "No Deployments";
          } else {
            return this.deployedBy(params.data, env["Value"]);
          }
        },
      };

      columns.push(colDef);
    }

    return columns;
  }


  private configureFormsGrid(): void {
    this.initialRowDataLoad$ = [];
    this.formsGridOptions = <GridOptions>{
      rowSelection: "single",
      domLayout: 'normal',
      columnDefs: this.createFormsColumDef(),
      enableFilter: true,
      enableSorting: true,
      pagination: true,
      paginationPageSize: 10,
      rowHeight: 30,
      enableColResize: true,
      context: { componentParent: this },
      onGridReady: () => {
        this.formsGridOptions.api.setRowData(this.formsData);
        this.formsGridOptions.api.sizeColumnsToFit();
      },
      onRowClicked: (event) => {
        
        
      },
      onFilterChanged: (event) => {},
      onSortChanged: (event) => {},
      onFilterModified: function (event) {
        //console.log(event);
      },
    };
  }

  /**
   * configure forms grid columns
   */
  private createFormsColumDef(): any[] {
    let columns = [
      {
        headerName: "Form Number",
        field: "InternalFormNumber",
        headerTooltip: "Form Number",
        minWidth:100,
        tooltipValueGetter: (params) => params.value,
        filter: "agTextColumnFilter",
      },
      {
        headerName: "Form Name",
        field: "FormName",
        minWidth:100,
        headerTooltip: "Form Name",
        tooltipValueGetter: (params) => params.value,
        filter: "agTextColumnFilter",
      },
      { headerName: "LOB", field: "LOB",   minWidth:100,  headerTooltip: "LOB",tooltipValueGetter: (params) => params.value, filter: "agTextColumnFilter" },
      {
        headerName: "Effective Date",
        field: "EffectiveDate",
        headerTooltip: "Effective Date",
        minWidth:100,
        tooltipValueGetter: (params) => params.value,
        filter: "dateFilterComponent",
        sort: "desc",
        cellRenderer: (params) => {
          return this.dateTimePipe.transform(
            params.value,
            "string",
            "MM/DD/YYYY"
          );
        },
      },
      {
        headerName: "Expiration Date",
        field: "ExpirationDate",
        minWidth:100,
        headerTooltip: "Expiration Date",
        tooltipValueGetter: (params) => params.value,
        filter: "dateFilterComponent",
        sort: "desc",
        cellRenderer: (params) => {
          return this.dateTimePipe.transform(
            params.value,
            "string",
            "MM/DD/YYYY"
          );
        },
      },
      {
        headerName: "Submission Expiration Date",
        field: "SubmissionExpirationDate",
        minWidth:250,
      
        headerTooltip: "Submission Expiration Date",
        tooltipValueGetter: (params) => params.value,
        filter: "dateFilterComponent",
        sort: "desc",
        cellRenderer: (params) => {
          return this.dateTimePipe.transform(
            params.value,
            "string",
            "MM/DD/YYYY"
          );
        },
      },
      {
        headerName: "Modified Date",
        field: "ModifiedDate",
        minWidth:100,
        headerTooltip: "Modified Date",
        tooltipValueGetter: (params) => params.value,
        filter: "dateFilterComponent",
        sort: "desc",
        cellRenderer: (params) => {
          return this.dateTimePipe.transform(
            params.value,
            "string",
            "MM/DD/YYYY h:mm A"
          );
        },
      },
      {
        headerName: "Modified By",
        field: "ModifiedByName",
        minWidth:100,
        headerTooltip: "Modified By",
        tooltipValueGetter: (params) => params.value,
        filter: "agTextColumnFilter",
      },
    ];

    return columns;
  }

  // Close overlay if clicked outside of it
  @HostListener('document:click', ['$event'])
  onClickOutside(event: MouseEvent) {
    const overlay = document.querySelector('.overlay-content');
    if (this.isOverlayVisible && overlay && !overlay.contains(event.target as Node)) {
      this.closeOverlay();
    }
  }

  closeOverlay() {
    this.isOverlayVisible = false;
    this.activeModal.close();
  }


 
}
