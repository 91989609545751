<div class="component-container">
  <div class="col-12">
    <mat-tab-group class="vex-tabs" (selectedTabChange)="onTabChange($event)">
      <mat-tab label="Summary">
        <div style="font-size:30px;font-family:EncodeSans-Regular !important;padding-top: 5px;">Migration Job Statistics</div>
        <div class="iframe-container">

          <mat-accordion>

            <mat-expansion-panel *ngFor="let summary of this.migrationLogs.summaryLogs">
              <mat-expansion-panel-header class="mdo-mat-panel-header">
                <mat-panel-title>
                  <div class="row pp-current-row">
                    <h3 style="margin-left:30px;">MGME</h3>
                  </div>
                </mat-panel-title>
              </mat-expansion-panel-header>

              <p class="pp-history-title">Details</p>


              <div class="pp-history">
                <div class="row">
                  <div class="col-md-4">
                    &nbsp;
                  </div>
                  <div class="col-md-3 pp-history-col-2">
                    Files Uploaded: {{summary.FilesUploaded}}
                  </div>
                  <div class="col-md-2 pp-history-col-3">
                    Files Failed: {{summary.FilesFailed}}
                  </div>
                  <div class="col-md-3 pp-history-col-4">
                    Time To Complete: {{summary.TimeToComplete}}
                  </div>
                </div>
              </div>

            </mat-expansion-panel>

          </mat-accordion>
        </div>
      </mat-tab>
      <mat-tab label="Errors">
        <div style="font-size:30px;font-family:EncodeSans-Regular !important;padding-top: 5px;">Migration Errors</div>
        <div class="iframe-container">
          <ag-grid-angular class="ghostPortal ag-theme-balham box-shadow" [gridOptions]="migrationErrorGridOptions" style="height: 400px; width: -webkit-fill-available;"></ag-grid-angular>
          <!--<mat-accordion>

            <mat-expansion-panel *ngFor="let errorLog of this.migrationLogs.errorLogs">
              <mat-expansion-panel-header class="mdo-mat-panel-header">
                <mat-panel-title>
                  <div class="row pp-current-row">
                    <h4 style="margin-left:30px;">{{errorLog.filename}}</h4>
                  </div>
                </mat-panel-title>
              </mat-expansion-panel-header>

              <p class="pp-history-title">Details</p>


              <div class="pp-history">
                <div class="row">
                  <div class="col-md-4">
                    &nbsp;
                  </div>
                  <div class="col-md-3 pp-history-col-2">
                   Item Name: {{errorLog.itemname}}
                  </div>
                  <div class="col-md-2 pp-history-col-3">
                    Item Number: {{errorLog.itemnum}}
                  </div>
                  <div class="col-md-3 pp-history-col-4">
                    Message: {{errorLog.errorMessage}}
                  </div>
                </div>
              </div>

            </mat-expansion-panel>

          </mat-accordion>-->
        </div>
      </mat-tab>
      <mat-tab label="Validation">
        <div id="summary-grid-container" class="col-12" style="padding-top: 10px">
          <ag-grid-angular class="ag-theme-balham fast_panel_table"
                           [gridOptions]="resultsGridOptions"
                           style="height: 400px">
          </ag-grid-angular>
        </div>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>
