
<div *ngIf="isOverlayVisible" class="overlay" (click)="closeOverlay($event)">
  <div class="overlay-content" (click)="$event.stopPropagation()">
    <div class="close-button-container">
      <button class="close-btn" (click)="closeOverlay($event)">
        <i class="fas fa-times"></i> 
      </button>
    </div>
    <div class="row">
      <label class="label_heading">Deployment History:</label>
        <ag-grid-angular
          class="ag-theme-balham fast_panel_table"
          [gridOptions]="historyGridOptions"
          style="height: 300px;  width:100%;overflow-x: hidden; border-radius: 7px; box-shadow: 0 0 7px #fff;"
        ></ag-grid-angular>
      </div>
      <div class="row">
        <label class="label_heading">Forms:</label>
        <ag-grid-angular
          class="ag-theme-balham fast_panel_table"
          [gridOptions]="formsGridOptions"
          style="height: 300px; width:100%;overflow-x: hidden; border-radius: 7px; box-shadow: 0 0 7px #fff;"
        ></ag-grid-angular>
      </div>
    </div>  
  </div>
  
